export enum RouteCode {
  MANGS_AKTOB = 'Мангыстау - Актобе',
  AKTOB_MANGS = 'Актобе - Мангыстау',
  ATYRAU_DINUR = 'Атырау - Дины Нурпеисовой',
  DINUR_ATYRAU = 'Дины Нурпеисовой - Атырау',
}

export interface RouteStations {
  routeCode: RouteCode
  stationsUicList: Array<string>
}
