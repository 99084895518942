import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AxiosResponse } from 'axios'
import { RoutePrice, RoutesSchema } from 'interfaces/RoutesPrices'

interface RoutesPrices {
  prices: Array<RoutePrice>
}

const getSinglePrices = async (params: RoutesSchema) => {
  const {
    data: { prices },
  } = await baseAxios.post<RoutesSchema, AxiosResponse<RoutesPrices>>(URLS.getRoutesPrices, params)
  return prices
}

export const getRoutesPrices = async (schema: Array<RoutesSchema>) => {
  const promises = schema.map(params => getSinglePrices(params))
  const result = await Promise.all(promises)
  return result.flat()
}
