import { Grid } from 'components/ui/Grid'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

export const CoreGridItem = styled.div``

export const CoreGridColumn = styled.div<{ left?: boolean; right?: boolean }>`
  display: grid;
  grid-gap: 16px;
  ${({ left }) =>
    left &&
    css`
      grid-column: 1 / span 1;
      grid-auto-rows: min-content;
    `}
  ${({ right }) =>
    right &&
    css`
      grid-column: span 1 / -1;
      grid-auto-rows: min-content;
    `}
`

export const CoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 24px;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr;
    ${CoreGridColumn} {
      ${CoreGridItem} {
        &:nth-child(n + 2) {
          display: none;
        }
      }
    }
  }
`

export const StationGrid = styled(Grid)`
  @media screen and (max-width: 1250px) {
    grid-template-rows: auto;
  }
`

export const StationTitleWrapper = styled.div`
  span {
    @media screen and (max-width: 1130px) {
      font-size: 22px;
    }
  }
`

export const StationItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  cursor: pointer;
  span {
    transition: all 0.2s ease;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.palette.secondary.MAIN};
    }
  }
`
