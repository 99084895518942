import { RoutesSchema } from 'interfaces/RoutesPrices'
import { RouteCode } from 'interfaces/RouteStations'

const AKTOB_MANGS_SCHEMA: Array<RoutesSchema> = [
  {
    origin: '2704600',
    destination: [
      '2704980',
      '2704870',
      '2704803',
      '2704807',
      '2704819',
      '2704822',
      '2704817',
      '2704808',
      '2704826',
    ],
  },
  {
    origin: '2704845',
    destination: [
      '2704980',
      '2704870',
      '2704803',
      '2704807',
      '2704819',
      '2704822',
      '2704817',
      '2704808',
    ],
  },
  {
    origin: '2704807',
    destination: [
      '2704980',
      '2704870',
      '2704803',
      '2704600',
      '2704819',
      '2704822',
      '2704817',
      '2704808',
    ],
  },
  { origin: '2704817', destination: ['2704980', '2704870', '2704803', '2704600'] },
  { origin: '2704822', destination: ['2704980', '2704870'] },
]

const ATYRAU_DINUR_SCHEMA: Array<RoutesSchema> = [
  {
    origin: '2704830',
    destination: ['2704791', '2705874', '2704792', '2704994', '2704793', '2705865'],
  },
  {
    origin: '2705874',
    destination: ['2704830', '2704793', '2704791', '2704792', '2704994'],
  },
  {
    origin: '2704793',
    destination: ['2704830', '2704791', '2705874', '2704792', '2705865'],
  },
  {
    origin: '2705865',
    destination: ['2704830', '2704791'],
  },
  { origin: '2704791', destination: ['2704830', '2704793'] },
]

const MAIN_PAGE_SCHEMA: Array<RoutesSchema> = [
  {
    origin: '2704830',
    destination: ['2704791', '2705874', '2704994', '2704793'],
  },
  {
    origin: '2704600',
    destination: [
      '2704980',
      '2704870',
      '2704803',
      '2704807',
      '2704819',
      '2704822',
      '2704817',
      '2704808',
      '2704826',
    ],
  },
  {
    origin: '2705874',
    destination: ['2704830', '2704793', '2704791', '2704792', '2704994'],
  },
  {
    origin: '2704845',
    destination: ['2704980', '2704870', '2704807', '2704819', '2704808'],
  },
  {
    origin: '2705865',
    destination: ['2704830', '2704791'],
  },
]

export const getSchemaByRouteCode = (routeCode?: RouteCode) => {
  if (routeCode === RouteCode.MANGS_AKTOB || routeCode === RouteCode.AKTOB_MANGS)
    return AKTOB_MANGS_SCHEMA
  if (routeCode === RouteCode.ATYRAU_DINUR || routeCode === RouteCode.DINUR_ATYRAU)
    return ATYRAU_DINUR_SCHEMA
  return MAIN_PAGE_SCHEMA
}
