import { Box } from 'components/ui/Box'
import { Button } from 'components/ui/Button'
import { Paper } from 'components/ui/Paper'
import { Skeleton } from 'components/ui/Skeleton'
import { Typography } from 'components/ui/Typography'
import { RouteStations } from 'interfaces/RouteStations'
import { Station } from 'interfaces/Station'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { useTheme } from 'styled-components'
import { formatPrice } from 'utilities/formatPrice'

import { usePrices } from './hooks/usePrices'
import { getSchemaByRouteCode } from './schemas'
import {
  CoreGrid,
  CoreGridColumn,
  CoreGridItem,
  StationGrid,
  StationItem,
  StationTitleWrapper,
  Wrapper,
} from './styles'

interface Destination {
  station: Station
  price: number
}

interface PopularRoute {
  origin: Station
  destination: Array<Destination>
}

interface Props {
  activeRoute?: RouteStations
}

export const PopularRoutes: FC<Props> = ({ activeRoute }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const schema = getSchemaByRouteCode(activeRoute?.routeCode)
  const { routes, isLoading } = usePrices(schema)

  const [largest, averageOne, averageTwo, smallOne, smallest] = routes

  const renderStation = (popularRoute: PopularRoute) => {
    const { origin } = popularRoute

    return (
      <Paper>
        <Box padding={16}>
          <StationTitleWrapper>
            <Typography typography={theme.typography.variants.title.H1}>{origin.name}</Typography>
          </StationTitleWrapper>
          <Box marginTop={12}>
            <StationGrid container columnGap={65} cols='1fr'>
              {popularRoute.destination.map(destination => (
                <a
                  key={destination.station.uic}
                  href={`/${origin.shortCode}-${destination.station.shortCode}`}
                >
                  <StationItem>
                    <Typography typography={theme.typography.variants.content.MAIN}>
                      {origin.name} - {destination.station.name}
                    </Typography>
                    <Typography typography={theme.typography.variants.content.BOLD}>
                      {t('core:from')} {formatPrice(destination.price)}
                    </Typography>
                  </StationItem>
                </a>
              ))}
            </StationGrid>
          </Box>
        </Box>
      </Paper>
    )
  }

  return (
    <Wrapper>
      <Typography
        as='h2'
        typography={theme.typography.variants.title.H1}
        color={theme.palette.text.ALTERNATIVE}
      >
        {t('core:popularRoutes')}
      </Typography>
      <CoreGrid>
        <CoreGridColumn left>
          {isLoading && (
            <>
              <Skeleton height={200} />
              <Skeleton height={150} />
              <Skeleton height={150} />
            </>
          )}
          {!isLoading && (
            <>
              <CoreGridItem>{renderStation(largest)}</CoreGridItem>
              <CoreGridItem>{renderStation(smallOne)}</CoreGridItem>
              <CoreGridItem>{renderStation(smallest)}</CoreGridItem>
            </>
          )}
        </CoreGridColumn>
        <CoreGridColumn right>
          {isLoading && (
            <>
              <Skeleton height={250} />
              <Skeleton height={265} />
            </>
          )}
          {!isLoading && (
            <>
              <CoreGridItem>{renderStation(averageTwo)}</CoreGridItem>
              <CoreGridItem>{renderStation(averageOne)}</CoreGridItem>
            </>
          )}
        </CoreGridColumn>
      </CoreGrid>
      <Box marginTop={24}>
        <Link passHref href='/napravleniya'>
          <a>
            <Button
              fill='outlined'
              textColor={theme.palette.text.ALTERNATIVE}
              color={theme.palette.text.ALTERNATIVE}
            >
              {t('core:watchAll')}
            </Button>
          </a>
        </Link>
      </Box>
    </Wrapper>
  )
}
