import { getRoutesPrices } from 'api/requests/getRoutesPrices'
import { RoutePrice, RoutesSchema } from 'interfaces/RoutesPrices'
import { Station } from 'interfaces/Station'
import { useMemo, useState } from 'react'
import { useMount, useToggle } from 'react-use'
import { useAppSelector } from 'store/hooks'
import { getEntities } from 'store/selectors/getEntities'

export const usePrices = (schemas: Array<RoutesSchema>) => {
  const [prices, setPrices] = useState<Array<RoutePrice>>([])
  const [isLoading, toggleLoading] = useToggle(true)

  const stations = useAppSelector(getEntities<Station>('stations'))

  const getStationByUic = (uic: string) => stations.find(s => s.uic === uic) as Station

  const getPriceByStations = (origin: string, destination: string) =>
    prices.find(price => price.origin === origin && price.destination === destination)?.price || 0

  const fetchPrices = async () => {
    const fetchedPrices = await getRoutesPrices(schemas)
    setPrices(fetchedPrices)
    toggleLoading(false)
  }

  useMount(() => {
    void fetchPrices()
  })

  const routes = useMemo(
    () =>
      schemas.map(schema => ({
        origin: getStationByUic(schema.origin),
        destination: schema.destination.map(destination => ({
          station: getStationByUic(destination),
          price: getPriceByStations(schema.origin, destination),
        })),
      })),
    [schemas, prices, stations]
  )

  return {
    routes,
    isLoading,
  }
}
